export default {
    /**
     * 判断数据是否包含某一个数据
     */
    checkContainerStrFromArray(sourseList,str){
        if(!sourseList || sourseList.length == 0){
            return false;
        }
        for (let i = 0; i < sourseList.length; i++) {
             const object = sourseList[i];
             if(object == str){
                 return true;
             }
        }
        return false;
    }
}
