import { HudGlobal } from "../../util/HudGlobal";
import planApi from "../../api/planApi";
import { Constants } from "../../util/Constants";
import hoursApi from "../../api/hours/hoursApi";
import OfflineParam from "../../util/OfflineParam";
import ArrayHelper from "../../util/helper/ArrayHelper";
import DeviceHelper from "../../util/helper/DeviceHelper";
import TBDriver from "@tibi/vue-driver/TBDriver.js";
import StoreDBManger from "../../util/StoreDBManger";
import TibiMatomoManger from "../../util/manager/TibiMatomoManger";
import TrackConstants from "../../util/constants/TrackConstants";
import OverTrainingDialog from "../prepare-training/components/over-training-dialog/OverTrainingDialog.vue";
import { getCurrentSystemType, getCurrentUserId, getCurrentUserOrgId } from "../../util/user/UserManger";
import { SYSTEM_TYPE } from "../../util/constants/EnumConstants";
import { gotoHomeList } from "../middle-ware/utils/ControlRouter";
import { getOfflineRecordIdFromDB, setOfflineRecordIdToDB } from "../../util/db/TrainDBManger";
import SelectLeaderDialog from "../prepare-training/components/select-leader-dialog/SelectLeaderDialog.vue";
import SelectRoomDialog from "../prepare-training/components/select-room-dialog/SelectRoomDialog.vue";
import { COMMON } from "../../util/constants/RouteConstant";
import { getQueryStringParams } from "../../util/helper/URLHepler";

export default {
    components: {
        OverTrainingDialog,
        SelectLeaderDialog,
        SelectRoomDialog,
    },
    data() {
        return {
            routeParam: {
                type: "",
                planId: "",
            },

            // 计划数据
            planData: {
                // 培训记录
                offlineRecordDtoList: [],
                planLeaderList: [],
                classRoomDto: {},
                planId: null,
                videoList: [], //视频列表
                docList: [], //文件列表
                picList: [], //图片列表
                planBaseInfoDto: {}, // 计划信息
            },
            // 动画
            planDataExtra: {
                startLoading: false, // 开始会议
                endLoading: false, // 结束会议
                continueLoading: false, // 继续会议
            },
            // 选择的课件列表
            courseDataSelectedList: [],

            // 培训信息查询, 学时系统
            offlineRecordParams: {
                // 现场学习ID
                offlineRecordId: "",
                // 现场学习教室id
                classroomId: "",
                //  身份类型编码（已知的情况下传，A8PP扫码情况可以不传）
                // (学员(system_org_indentity_student)，考核人员(system_org_indentity_assessors)，安全管理员
                ipAddr: "127.0.0.1",
                // 130：查询学习库是否存在，131：结束现场培训记录
                operationType: 130,
                orgId: "",
                userId: "",
                realName: "",
                // 签到来源（10：pc,20：Android，21：iOS，22：公众号，23小程序)
                signSource: 10,
                // 学习场景（10：pc网络，14：现场考试，15：pc现场学习，20：Android，21：iOS，22：公众号，23：小程序）
                sourceType: 15,
                planId: "",
                // trainingId: "",
                parameterMap: null,
            },

            // 开始培训
            overTrainingDialogObject: {
                overTrainingDialogVisible: false, //是否显示overTrainingDialog
                type: "", // 弹窗类型 add或 end
                message: "",
                itemData: null,
            },
            //选择培训弹框
            dialogTrainObject: {
                visible: false,
            },
            // 选择监督人的弹框
            dialogLeaderObject: {
                visible: false,
                currentLeader: null, //当前选择的管理员
            },

            // 教室弹框
            dialogClassroomObject: {
                visible: false,
                currentRoom: null, // 当前的教室
                typeCode: 1,
            },
        };
    },
    created() {},
    computed: {
        /**
         * 是否有进行中的计划
         */
        hasProcessPlan() {
            if (!this.planData || !this.planData.offlineRecordDtoList || this.planData.offlineRecordDtoList.length === 0) {
                return false;
            }
            const offlineRecordDtoList = this.planData.offlineRecordDtoList;
            const processArr = [];
            // lzb - 2020-08-06 新增判断
            if (offlineRecordDtoList && offlineRecordDtoList.length > 0) {
                offlineRecordDtoList.map((data) => {
                    // 状态（1：进行中，2：结束）
                    if (data.state == 1) {
                        processArr.push(data.id);
                    }
                });
            }
            return processArr.length > 0;
        },
        /**
         * 是否会现场会议
         */
        isOfflineMeeting() {
            return getCurrentSystemType() == SYSTEM_TYPE.MEETING;
        },

        /**
         * 是否有显示开始培训  lzb 20240115 bug=16585
         */
        isShowStartTrainButton() {
            if (!this.planData || !this.planData.planBaseInfoDto) {
                return false;
            }
            const state = this.planData.planBaseInfoDto.state;
            if (state == 25 || state == 30) {
                return false;
            }
            return true;
        },
    },
    methods: {
        /*
         *   点击开始培训
         * */
        onClickStartTrain() {
            TBDriver.resetSteps();
            TibiMatomoManger.sendPrepareTrainingTrackEvent(TrackConstants.eventNameKeys.prepare_training_menu_start_train);
            this.checkPlanLeader() && this.loadAddOfflineRecordData();
        },
        /**
         * 点击继续培训
         */
        onClickContinueTrain(item) {
            // 当前操作人不是 培训记录创建者不能继续培训操作
            if (this.isCurrentCreator(item)) {
                HudGlobal.showAlertMessage(`[${item.createUser}]正在组织当前培训，您无法进行操作`);
                return;
            }
            const that = this;
            const params = {
                // 现场学习ID
                offlineRecordId: item.offlineRecordId,
                // 现场学习教室id
                classroomId: item.classroomId,
                ipAddr: "127.0.0.1",
                operationType: 130, // 130：查询学习库是否存在，131：结束现场培训记录
                orgId: getCurrentUserOrgId(),
                userId: getCurrentUserId(),
                signSource: 10, // 签到来源（10：pc,20：Android，21：iOS，22：公众号，23小程序)
                sourceType: 15, // 学习场景（10：pc网络，14：现场考试，15：pc现场学习，20：Android，21：iOS，22：公众号，23：小程序）
                planId: item.planId,
                // trainingId: "",
                parameterMap: null,
            };
            this.planDataExtra.continueLoading = true;
            hoursApi
                .hoursAddOrMod(params)
                .then((res) => {
                    if (res.success) {
                        // 判断是否是多管理员窗口学习
                        if (res.data.pushUserId != null && res.data.pushUserId.length != 0) {
                            // 判断当前用户是否在pushUserId中
                            const userId = that.$store.getters.userId;
                            const result = ArrayHelper.checkContainerStrFromArray(res.data.pushUserId, userId);
                            // 不在里面需要创建
                            if (!result) {
                                // 包含userid - 说明有多端学习-需要创建新的学习库
                                that.addOfflineRecordOfMoreData(item);
                                return;
                            }
                        }
                        // 有成功记录-继续跳转签到
                        that.handleOffLineTrainRecordSuccessResponse(item);
                    } else {
                        that.planDataExtra.continueLoading = false;
                        // 重新加载培训历史记录
                        HudGlobal.showAlertMessage(res.description || "此培训记录已结束或异常");
                        setTimeout(function() {
                            that.getPlanOne();
                        }, 500);
                    }
                })
                .catch((err) => {
                    that.planDataExtra.continueLoading = false;
                });
            TibiMatomoManger.sendPrepareTrainingTrackEvent(TrackConstants.eventNameKeys.prepare_training_menu_continuing_train);
        },

        /**
         * 是否当前创建人
         */
        isCurrentCreator(item) {
            return item.createUserId !== getCurrentUserId();
        },
        /*
         * 继续培训查询
         * 退出, 返回到计划列表页面
         * */
        logoutDo() {
            const query = getQueryStringParams();
            const type = query.type ? query.type : getCurrentSystemType();
            const message = type == SYSTEM_TYPE.MEETING ? "是否返回会议列表" : "是否返回计划列表";
            HudGlobal.showAlertConfirmMessage(message).then(() => {
                gotoHomeList({});
            });
        },

        /**
         * 打开密码输入弹窗
         */
        openOverTrainingDialog(item) {
            this.overTrainingDialogObject.overTrainingDialogVisible = true;
            this.overTrainingDialogObject.type = "add";
            this.overTrainingDialogObject.itemData = item;
            this.overTrainingDialogObject.message = "开始新培训会结束之前的培训记录，培训中的学员会强制进行签退，请谨慎操作";
        },
        /**
         * 输入密码之后的弹框
         */
        onPwdAfterStartTrain() {
            const item = this.overTrainingDialogObject.itemData;
            this.loadAddOfflineRecordData(1, item.offlineRecordId);
        },
        /**
         * 打开安全管理员 的更换弹窗
         */
        openSelectLeaderDialog(description, typeCodes) {
            const that = this;
            const object = that.planData.planLeaderList.find((item) => item.typeCode === typeCodes);
            that.$alert(description, "温馨提示", {
                confirmButtonText: "更换",
                callback: (action) => {
                    if (action === "confirm") {
                        that.onClickChangeLeader(object);
                    }
                },
            });
        },
        // ========================================处理监督人V1.0.9 lzb 2021-06-17=============================================//

        /**
         * 选择更换管理人员V1.0.9 lzb 2021-06-17
         */
        onClickChangeLeader(item) {
            this.dialogLeaderObject.currentLeader = item;
            // 埋点统计
            TBDriver.resetSteps();

            let key = TrackConstants.eventNameKeys.prepare_training_menu_change;
            if (item.typeCode == Constants.organizeIndentityType.INDENTITY_SAFE_ADMIN) {
                key = TrackConstants.eventNameKeys.prepare_training_menu_change_safe_adminer;
            } else if (item.typeCode == Constants.organizeIndentityType.INDENTITY_ASSESSORS) {
                key = TrackConstants.eventNameKeys.prepare_training_menu_change_auditor_adminer;
            } else if (item.typeCode == Constants.organizeIndentityType.INDENTITY_LECTURER) {
                key = TrackConstants.eventNameKeys.prepare_training_menu_changepr_lecturer_adminer;
            } else {
                key = key + item.typeCode;
            }
            TibiMatomoManger.sendPrepareTrainingTrackEvent(key);

            this.dialogLeaderObject.visible = true;
        },
        /**
         *  监听更换学员选择
         */
        monitorSelectLeaderObject(obj) {
            // 取消弹框
            let name = TrackConstants.eventNameKeys.prepare_training_menu_select;
            for (let i = 0; i < this.planData.planLeaderList.length; i++) {
                const item = this.planData.planLeaderList[i];
                if (obj.leaderId == item.leaderId) {
                    item.userId = obj.userId;
                    item.cardNo = obj.cardNo;
                    item.realName = obj.realName;
                    // 2021-11-01 fbz
                    item.faceId = obj.faceId;
                    item.faceUrl = obj.faceUrl;
                    if (item.typeCode == Constants.organizeIndentityType.INDENTITY_SAFE_ADMIN) {
                        name = TrackConstants.eventNameKeys.prepare_training_menu_select_safe_personnel;
                    } else if (item.typeCode == Constants.organizeIndentityType.INDENTITY_ASSESSORS) {
                        name = TrackConstants.eventNameKeys.prepare_training_menu_select_auditor_personnel;
                    } else if (item.typeCode == Constants.organizeIndentityType.INDENTITY_LECTURER) {
                        name = TrackConstants.eventNameKeys.prepare_training_menu_select_lecturer_personnel;
                    } else {
                        name = name + item.typeCode;
                    }

                    break;
                }
            }
            // 发送事件
            TibiMatomoManger.sendPrepareTrainingTrackEvent(name);
        },
        // =======================================选择教室=============================================//

        /**
         * 点击切换教室 V1.0.9 lzb 2021-06-17
         */
        onClickChangeRoom() {
            // 埋点事件
            TBDriver.resetSteps();
            TibiMatomoManger.sendPrepareTrainingTrackEvent(TrackConstants.eventNameKeys.prepare_training_menu_change_class_room);
            this.dialogClassroomObject.visible = true;
            this.dialogClassroomObject.typeCode = this.isOfflineMeeting ? 3 : 1;
            this.dialogClassroomObject.currentRoom = this.planData.classRoomDto;
        },
        /**
         * 列表 教室 选择 教室选择 V1.0.9 lzb 2021-06-17
         * @param item
         */
        onClickListClassroomSelected(item) {
            this.planData.classRoomDto = item;
            TibiMatomoManger.sendPrepareTrainingTrackEvent(TrackConstants.eventNameKeys.prepare_training_menu_select_class_room);
        },
        // =========================================新增记录请求====================================================//
        /**
         * 创建新的学习记录 - 多端
         */
        addOfflineRecordOfMoreData(item) {
            const param = {
                userId: getCurrentUserId(),
                realName: this.$store.getters.realName,
                planId: item.planId,
                offlineRecordId: item.offlineRecordId,
                classroomId: item.classroomId,
                ipAddr: DeviceHelper.getIpAddress(),
            };
            const that = this;
            hoursApi.addOfflineRecordOfMoreWindow(param).then((res) => {
                if (res.success) {
                    // 直接用之前的offlineRecordId
                    that.handleOffLineTrainRecordSuccessResponse(param.offlineRecordId);
                } else {
                    if (res.code !== 102) {
                        HudGlobal.showWarningWithMessage(res.description || "现场培训记录新增失败!");
                    }
                }
            });
        },

        /*
         * 现场培训记录新增 （记录新增有直接开始培训[null/0] 和重新开始培训[1]）
         *   null/0:新增，1：结束已有记录并新增 : addType
         * offlineRecordId
         * */
        loadAddOfflineRecordData(addType, offlineRecordId) {
            // 参数重新组装
            const params = this.processAddRecordParam(addType, offlineRecordId);
            const that = this;
            this.planDataExtra.startLoading = true;
            planApi
                .addOfflineRecord(params)
                .then((res) => {
                    if (res.success) {
                        that.handleOffLineTrainRecordSuccessResponse(res.data);
                    } else {
                        that.planDataExtra.startLoading = false;
                        that.handleOffLineTrainRecordFail(res);
                    }
                })
                .catch((err) => {
                    that.planDataExtra.startLoading = false;
                });
        },
        /**
         * 处理开始会议请求参数
         */
        processAddRecordParam(addType, offlineRecordId) {
            const param = {
                offlineCourseSaveFormList: [], // 课程
                offlineLeaderSaveFormList: this.planData.planLeaderList, // 主持人
                classroomId: this.planData && this.planData.classRoomDto.classroomId,
                classroomName: this.planData && this.planData.classRoomDto.classroomName,
                orgId: this.planData && this.planData.planBaseInfoDto && this.planData.planBaseInfoDto.orgId,
                orgName: this.planData && this.planData.planBaseInfoDto && this.planData.planBaseInfoDto.orgName,
                planId: this.planData && this.planData.planBaseInfoDto && this.planData.planBaseInfoDto.planId,
                planName: this.planData && this.planData.planBaseInfoDto && this.planData.planBaseInfoDto.planName,
                startTime: null, // 前端固定
                planDuration: null, // 前端固定
                state: 1,
                trainingNuimber: 0, // 前端固定
                userSignInCount: 0, // 前端固定
                addType,
                finishOfflineRecordId: offlineRecordId ? offlineRecordId : null, // lzb 20231227
            };
            const courseList = this.processCourseDataList();
            param.offlineCourseSaveFormList = courseList;
            return param;
        },
        /**
         * 处理课件内容
         */
        processCourseDataList() {
            // 课件处理-包含原始数据的3个部分
            let courseList = [];

            if (this.routeParam.type == SYSTEM_TYPE.MEETING) {
                const baseInfo = this.planData.planBaseInfoDto;
                // 视频处理
                if (baseInfo.videoList && baseInfo.videoList.length > 0) {
                    baseInfo.videoList.map((item) => {
                        item.checked && courseList.push(item);
                    });
                }
                // 文档处理
                if (baseInfo.docList && baseInfo.docList.length > 0) {
                    baseInfo.docList.map((item) => {
                        item.checked && courseList.push(item);
                    });
                }
                // 图片处理
                if (baseInfo.picList && baseInfo.picList.length > 0) {
                    baseInfo.picList.map((item) => {
                        item.checked && courseList.push(item);
                    });
                }
            } else {
                // 培训
                const allSelectedCourseData = this.getAllSelectedCourseData();
                // 将所有节点子节点置为空, 树节点返回的所有选中数据, 但有些包含了子节点,
                for (let i = 0; i < allSelectedCourseData.length; i++) {
                    allSelectedCourseData[i].children = null;
                }
                //　过滤掉所有目录数据 11:目录 21：课件，31：资料
                let filterCourseData = allSelectedCourseData.filter((data) => data.courseClazz != 11);
                courseList = filterCourseData;
            }
            return courseList;
        },
        /**
         * 处理新增培训记录成功
         */
        handleOffLineTrainRecordSuccessResponse(data) {
            let signInParams = {
                offlineRecordId: data.offlineRecordId,
            };
            setOfflineRecordIdToDB(signInParams.offlineRecordId);

            // 跳转签到
            this.gotoJumpSignIn(signInParams);
        },
        /**
         * 处理新增培训记录失败
         */
        handleOffLineTrainRecordFail(res) {
            const { code, description, data } = res;
            const that = this;
            switch (code) {
                case 80114: {
                    // 您的账号在当前计划有进行中的培训，是否结束正在进行的记录并开启新的培训
                    HudGlobal.showAlertConfirmMessages(res.description, null, "温馨提示", "开始新培训", "继续培训", true)
                        .then(() => {
                            that.loadAddOfflineRecordData(1, data.offlineRecordId);
                        })
                        .catch((action) => {
                            if (action === "cancel") {
                                const currentTrainingData = that.planData.offlineRecordDtoList.find(
                                    (item) => item.createUserId == getCurrentUserId() && item.state == 1
                                );
                                currentTrainingData && this.onClickContinueTrain(currentTrainingData);
                            }
                        });
                    break;
                }
                case 80115: {
                    HudGlobal.showAlertConfirmMessages(res.description, null, "温馨提示", "开始新培训")
                        .then(() => {
                            that.loadAddOfflineRecordData(1, data.offlineRecordId);
                        })
                        .catch(() => {});
                    break;
                }
                case 80123: {
                    // lzb 20231227 新增code
                    HudGlobal.showAlertConfirmMessages(res.description, null, "温馨提示", "开始新培训", "更换")
                        .then(() => {
                            that.loadAddOfflineRecordData(1, data.offlineRecordId);
                        })
                        .catch(() => {});
                    break;
                }
                case 80116: {
                    HudGlobal.showAlertConfirmMessages(res.description, null, "温馨提示", "开始新培训")
                        .then(() => {
                            that.openOverTrainingDialog(data);
                        })
                        .catch(() => {});
                    break;
                }
                case 80124: {
                    // lzb 20231227 新增code
                    HudGlobal.showAlertConfirmMessages(res.description, null, "温馨提示", "开始新培训", "更换")
                        .then(() => {
                            that.openOverTrainingDialog(data);
                        })
                        .catch(() => {});
                    break;
                }
                case 80118: {
                    // （未生效）设置参数，监督人不能同时进行多场培训时
                    that.openSelectLeaderDialog(res.description, res.data.typeCodes);
                    break;
                }
                default: {
                    HudGlobal.showErrorWithMessage(res.description);
                    break;
                }
            }
        },

        //===========================================获取计划信息=========================================================

        /**
         * 获取计划信息
         */
        getPlanOne() {
            let params = {
                planId: this.routeParam.planId,
            };
            const that = this;
            planApi.getPlanOne(params).then((res) => {
                if (res.success && res.data) {
                    let data = res.data;
                    that.planData.classRoomDto = data.classRoomDto || {};
                    that.planData.planBaseInfoDto = data.planBaseInfoDto || {};
                    that.planData.offlineRecordDtoList = data.offlineRecordDtoList || [];

                    // lzb V1.0.9 2021-06-16
                    that.planData.planLeaderList = data.planLeaderList || [];
                    if (that.routeParam.type == SYSTEM_TYPE.MEETING) {
                        that.handlePlanDetailData();
                    } else {
                        that.handleLeaderListDefaultValue(data);
                        // 检测操作引导
                        that.checkLivelearningOperationGuide();
                    }
                } else {
                    if (res.code !== 102) {
                        // HudGlobal.showMessage("获取计划数据失败!");
                    }
                }
            });
        },

        /**
         * 处理请求详情
         */
        handlePlanDetailData() {
            const that = this;

            this.planData.videoList = this.planData.planBaseInfoDto && this.planData.planBaseInfoDto.videoList;
            this.planData.docList = this.planData.planBaseInfoDto && this.planData.planBaseInfoDto.docList;
            this.planData.picList = this.planData.planBaseInfoDto && this.planData.planBaseInfoDto.picList;

            // 视频处理
            if (that.planData.videoList && that.planData.videoList.length > 0) {
                that.planData.videoList.map((item, index) => {
                    that.$set(item, "checked", true);
                });
            }
            // 文档处理
            if (that.planData.docList && that.planData.docList.length > 0) {
                that.planData.docList.map((item, index) => {
                    that.$set(item, "checked", true);
                });
            }
            // 图片处理
            if (that.planData.picList && that.planData.picList.length > 0) {
                that.planData.picList.map((item, index) => {
                    that.$set(item, "checked", true);
                });
            }
        },

        /**
         * 跳转到签到
         */
        gotoJumpSignIn(param) {
            StoreDBManger.saveDataToLocalStorage(StoreDBManger.storageKeys.SELECT_SIGN_TYPE, "0");

            const that = this;
            OfflineParam.initPlanParam({ planId: this.routeParam.planId, checkPlanValidFlag: true }, function() {
                that.planDataExtra.startLoading = false;
                that.planDataExtra.continueLoading = false;
                that.$router.push({
                    path: COMMON.VERIFY_PHOTO,
                    query: {
                        type: that.routeParam.type,
                        ...param,
                    },
                });
            });
        },

        /*
         * 修改培训记录, 结束培训记录
         * */
        onClickOfflineRecord(item) {
            let _this = this;
            TibiMatomoManger.sendPrepareTrainingTrackEvent(TrackConstants.eventNameKeys.prepare_training_menu_end_of_train);
            const params = {
                signType: 10,
                identityCode: Constants.organizeIndentityType.INDENTITY_STUDENT,
                state: 2,
                planId: this.routeParam.planId,
                offlineRecordId: getOfflineRecordIdFromDB(),
            };
            _this.planDataExtra.endLoading = true;
            // 如果有学员未签退需要弹窗进行密码验证
            // 如果没有学员需要签退 直接结束
            hoursApi.getOfflineUserList(params).then((response) => {
                const { success, description, data, code } = response;
                if (success) {
                    _this.overTrainingDialogObject.overTrainingDialogVisible = true;
                    _this.overTrainingDialogObject.type = "end";
                    _this.overTrainingDialogObject.itemData = item;
                    _this.overTrainingDialogObject.message = `
                    您确定结束[${item.createUser}]创建的培训记录吗，培训中的学员会强制进行签退，请谨慎操作`;
                } else {
                    _this.endCurrentRecord(item);
                }
            });
        },
        /**
         * @description: 结束培训
         * @param {*}
         * @return {*}
         */

        endCurrentRecord(item) {
            const _this = this;
            _this.offlineRecordParams.offlineRecordId = item.offlineRecordId;
            _this.offlineRecordParams.planId = item.planId;
            _this.offlineRecordParams.classroomId = item.classroomId;
            _this.offlineRecordParams.userId = _this.$store.getters.userId;
            _this.offlineRecordParams.operationType = 131;

            let params = {};
            Object.assign(params, _this.offlineRecordParams);

            hoursApi.hoursAddOrMod(params).then((res) => {
                console.log("结束培训,", res);
                _this.planDataExtra.endLoading = false;
                if (res.success) {
                    _this.getPlanOne();
                } else {
                    HudGlobal.showWarningWithMessage(res.description);
                }
            });
        },
        /**
         * 点击培训记录
         */
        onClickTrainRecordEvent(item) {
            TibiMatomoManger.sendPrepareTrainingTrackEvent(TrackConstants.eventNameKeys.prepare_training_menu_train_record);
            this.$router.push({
                path: COMMON.TRAIN_RECORD_LIST,
                query: {
                    type: this.routeParam.type,
                    ...item,
                },
            });
        },

        /**
         * 检查开始培训
         */
        checkPlanLeader() {
            // offlineLeaderSigninEnable 总控制
            if (!OfflineParam.params.offlineLeaderSigninEnable) {
                return false;
            }

            const that = this;

            // 2. 验证管理员
            if (this.routeParam.type === SYSTEM_TYPE.TRAIN) {
                const item = that.planData.planLeaderList.find((item) => {
                    const enable = that.getOfflineEnable(item.typeCode);
                    return enable && !item.realName;
                });
                if (item) {
                    // fbz 2021-11-18 1.0.16需求
                    HudGlobal.showAlertMessage(`请选择${item.leaderName}`, "温馨提示", () => {
                        that.onClickChangeLeader(item);
                    });
                    return false;
                }
            } else {
                //主持人验证
            }

            // 3. 验证课件 -非现场会议才严重
            if (!this.isOfflineMeeting) {
                const courseList = this.processCourseDataList();
                if (!courseList || courseList.length === 0) {
                    const message = `请至少选择一个${this.isOfflineMeeting ? "会议内容" : "培训内容"}`;
                    HudGlobal.showWarningWithMessage(message);
                    return false;
                }
            }

            return true;
        },

        /**
         * @description: 获取管理监督员是否必须签到
         * @param {*} typeCode
         * @return {*}
         */
        getOfflineEnable(typeCode) {
            let enable = 0;
            switch (typeCode) {
                // 安全管理员
                case Constants.organizeIndentityType.INDENTITY_SAFE_ADMIN:
                    enable = OfflineParam.params.offlineAdminSafeSigninEnable;
                    break;
                // 考核人员
                case Constants.organizeIndentityType.INDENTITY_ASSESSORS:
                    enable = this.getTempAssessors();
                    break;
                // 授课人
                case Constants.organizeIndentityType.INDENTITY_LECTURER:
                    enable = OfflineParam.params.offlineLecturerSigninEnable;
                    break;
            }
            return enable;
        },
        /**
         * @description: 考核人员类型比较特殊 特殊判断
         * @param {*}
         * @return {*}
         */
        getTempAssessors() {
            let tempAssessors = 0;
            // OfflineParam 为系统参数对象
            const offlineAssessorsSigninEnable = OfflineParam.params.offlineAssessorsSigninEnable;
            if (offlineAssessorsSigninEnable && this.planData.planBaseInfoDto.examType * 1 > 0) {
                tempAssessors = 1;
            }
            return tempAssessors;
        },
    },
};
