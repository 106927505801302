import planApi from "../../../../api/planApi";
import { Constants } from "../../../../util/Constants";
import TibiMatomoManger from "../../../../util/manager/TibiMatomoManger";
import { getCurrentUserOrgId } from "../../../../util/user/UserManger";

export default {
    name: "SelectLeaderDialog",
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        planId: {
            type: String,
            default: "",
        },
        currentLeader: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            // 培训管理人员数据
            leaderObject: {
                leaderList: [],
                pagerCount: 5,
                currentPage: 1,
                pageSize: 5,
                total: 0,
            },
            selectLeaderName: "", // 选中的人员类型
        };
    },
    watch: {
        visible: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.monitorLeaderCurrentPageChange(1);
                }
            },
        },
    },
    methods: {
        /**
         * 确定选择更换管理人员V1.0.9 lzb 2021-06-17
         */
        onClickSelectLeader(obj) {
            // 遍历更换管理人员
            this.$emit("get-data", obj);
            this.onCloseEvent();
        },
        /**
         * 关闭
         */
        onCloseEvent() {
            this.$emit("update:visible", false);
        },
        /**
         * 是否选中监督人 V1.0.9 lzb 2021-06-17
         */
        isSelectLeaderItem(item) {
            if (!this.currentLeader) {
                return false;
            }
            if (this.currentLeader.userId == item.userId) {
                return true;
            }

            return false;
        },
        /**
         * 获取培训管理人员分页 V1.0.9 lzb 2021-06-17
         */
        monitorLeaderCurrentPageChange(val) {
            this.leaderObject.currentPage = val;
            this.loadPlanLeadersList();
        },
        /**
         * 获取培训管理人员列表 V1.0.9 lzb 2021- 06-17
         */
        loadPlanLeadersList(cb) {
            const that = this;
            const param = {
                planId: this.planId,
                orgId: getCurrentUserOrgId(),
                currentPage: this.leaderObject.currentPage,
                pageSize: this.leaderObject.pageSize,
                leaderId: this.currentLeader ? this.currentLeader.leaderId : null,
            };
            planApi.getPlanLeaders(param).then((res) => {
                if (res.success) {
                    that.leaderObject.total = res.total;
                    that.leaderObject.leaderList = res.data || [];
                } else {
                    that.leaderObject.leaderList = [];
                }
                cb && cb(that.leaderObject.leaderList);
            });
        },
    },
};
