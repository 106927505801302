import IP from 'ip'
export default {
    /**
     * 获取浏览器ip地址
     */
    getIpAddress(){
        const ip =  IP.address();
        return ip;
    },
    /**
     * 获取UI给定屏幕尺寸-宽度
     */
    getDefaultSizeWidth(){
        return 1920.0;
    },
    /**
     * 获取UI给定屏幕尺寸-高度
     */
    getDefaultSizeHeight(){
        return 1080.0;
    },
    /**
     * 获取当前窗口的宽度
     */
    getCurrentWindowSizeWidth(){
        const width = document.documentElement.clientWidth;
        return width;
    },
    /**
     * 获取当前窗口的高度
     */
    getCurrentWindowSizeHeight(){
        const height = document.documentElement.clientHeight;
        return height;
    },
    /**
     * 屏幕宽度比例
     */
    getScreenWidthScale(){
        const radio = this.getCurrentWindowSizeWidth()/this.getDefaultSizeWidth();
        return radio;
    },
    /**
     * 屏幕高度比例
     */
    getScreenHeightScale(){
        const radio = this.getCurrentWindowSizeHeight()/this.getDefaultSizeHeight();
        return radio;
    }
}
