/*
 * @Author: fbz
 * @Date: 2021-08-25 14:38:48
 * @LastEditors: fbz
 * @LastEditTime: 2021-09-16 10:40:21
 */
import planApi from "../../../../api/planApi.js";
export default{
    name:'LoginOutDialog',
    props:{
        propDialogVisible:Boolean,
        propObject:Object
    },
    data(){
        const validateRequirePasswd = (rule, value, callback) => {
            const rules=/^(\w){6,18}$/;
            if(!value){
                callback(new Error('密码不能为空'))
            }else if(!rules.test(value)){
                callback(new Error('请输入6-18位的正确密码'))
            }else if(this.passWordError){
                callback(new Error('密码错误,重新输入'))
            }
            callback();
        }
        return{
            ruleForm:{
                passWord:'' // 密码
            },
            loading:false,
            dialogVisible:false ,// 弹窗是否显示
            rules:{
                passWord:[ {required: true, trigger: ['change','blur'], validator: validateRequirePasswd}]
            },
            passWordError:false,
            dialogObject:{
                message:'',
                type:'',
                itemData:{}
            }
        }
    },
    watch:{
        propDialogVisible:{
            immediate:true,
            handler(value){
                this.dialogVisible = value;
            }
        },
        propObject:{
            immediate:true,
            handler(value){
                const {message,type,itemData} = value;
                this.dialogObject.message = message;
                this.dialogObject.type = type;
                this.dialogObject.itemData = {...this.dialogObject.itemData,...itemData};
            },
            deep:true
        },
        'ruleForm.passWord':{
            immediate:true,
            handler(value){
                if(!value){
                    this.passWordError=false;
                }
            }
        }
    },
    methods:{
        handleClose(){
            this.$emit('update:propDialogVisible')
            this.$refs.ruleForm.resetFields()
            if(this.dialogObject.type === "end"){
                this.$emit('update:endOfflineRecordLoadding')
            }
        },
        /**
         * 确认退出
         */
         confirm(){
            const that = this;
            that.loading = true;
            that.$refs.ruleForm.validate((valid, object) => {
                if(valid){
                    this.passwdVerity();
                }else{
                    that.loading = false;
                }
            })
        },
        /**
         * 密码验证
         */
        passwdVerity(){
            const that = this;
            const params ={
                userId:that.$store.getters.userId,
                passwd:that.ruleForm.passWord
            }
            planApi.passwdVerity(params).then(response => {
                const {success,code} = response;
                if(success){
                    if(that.dialogObject.type === "add"){
                        that.$emit("addOfflineRecordData",1)
                    }else{
                        that.$emit("endCurrentRecord",that.dialogObject.itemData)
                    }
                    that.handleClose();
                }else if(code * 1 === 1){
                    that.passWordError=true
                    that.$refs.ruleForm.validateField('passWord');
                }
                that.loading = false;
            })
        }
    }
}