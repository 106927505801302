import planApi from "../../../../api/planApi";
import { getCurrentUserOrgId } from "../../../../util/user/UserManger";

export default {
    name: "SelectRoomDialog",
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        planId: {
            type: String,
            default: "",
        },
        typeCode: {
            type: Number, // 1培训 2考试 3现场会议, 目前后台没有明确定义
            default: 1,
        },
        currentRoom: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            classroomList: [],
            classroomListPage: {
                pagerCount: 5,
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
        };
    },
    watch: {
        visible: {
            immediate: true,
            handler(val) {
                val && this.monitorClassRoomCurrentPageChange(1);
            },
        },
    },
    methods: {
        /**
         * 列表 教室 选择 教室选择 V1.0.9 lzb 2021-06-17
         * @param item
         */
        onClickListClassroomSelected(item) {
            this.$emit("get-data", item);
            this.onCloseEvent();
        },
        /**
         * 关闭
         */
        onCloseEvent() {
            this.$emit("update:visible", false);
        },
        /**
         * 教室分页
         */
        monitorClassRoomCurrentPageChange(val) {
            this.classroomListPage.currentPage = val;
            this.loadClassRoomList();
        },
        /**
         * 获取教室列表 V1.0.9 lzb 2021-06-17
         */
        loadClassRoomList(callBack) {
            const that = this;
            const param = {
                planId: this.planId,
                orgId: getCurrentUserOrgId(),
                typeCode: this.typeCode, //  // 1培训 2考试, 目前后台没有明确定义
                currentPage: this.classroomListPage.currentPage,
                pageSize: this.classroomListPage.pageSize,
            };
            planApi.getClassroomList(param).then((res) => {
                if (res.success && res.data) {
                    let data = res.data;
                    that.classroomListPage.total = res.total;
                    that.classroomList = data || [];
                } else {
                    that.classroomList = [];
                }
                callBack && callBack(that.classroomList);
            });
        },
    },
};
