var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      staticClass: "select-class-room-dialog",
      attrs: {
        title: "更换教室",
        visible: _vm.visible,
        width: "30%",
        "before-close": _vm.onCloseEvent
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _vm.classroomList && _vm.classroomList.length === 0
        ? _c("div", [
            _c("div", { staticClass: "no-data" }, [
              _c("img", {
                attrs: { src: require("../../../../assets/img/no-data.png") }
              }),
              _c("div", { staticClass: "no-data-text" }, [
                _vm._v('"培训教室"暂时没有数据'),
                _c("br"),
                _vm._v("管理员添加后才能进行选择哦")
              ])
            ])
          ])
        : _vm._e(),
      _vm.classroomList && _vm.classroomList.length !== 0
        ? _c(
            "div",
            [
              _c("div", { staticClass: "classroom-style" }, [
                _c("span", [
                  _c("i", { staticClass: "selecte" }),
                  _vm._v("可选 ")
                ]),
                _c("span", [
                  _c("i", { staticClass: "selected" }),
                  _vm._v("已选中 ")
                ])
              ]),
              _c(
                "ul",
                { staticClass: "room-list" },
                _vm._l(_vm.classroomList, function(item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      class:
                        _vm.currentRoom &&
                        item.classroomId === _vm.currentRoom.classroomId
                          ? "blue"
                          : "",
                      attrs: { title: item.classroomName || "数据错误" },
                      on: {
                        click: function($event) {
                          return _vm.onClickListClassroomSelected(item)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(item.classroomName || "数据错误") + " "
                      )
                    ]
                  )
                }),
                0
              ),
              _c("el-pagination", {
                attrs: {
                  layout: "prev, pager, next",
                  "pager-count": _vm.classroomListPage.pagerCount,
                  "current-page": _vm.classroomListPage.currentPage,
                  "page-size": _vm.classroomListPage.pageSize,
                  total: _vm.classroomListPage.total
                },
                on: { "current-change": _vm.monitorClassRoomCurrentPageChange }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }