var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      staticClass: "select-leader-dialog",
      attrs: {
        title: "更换人员",
        visible: _vm.visible,
        width: "60%",
        "before-close": _vm.onCloseEvent
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _vm.leaderObject.leaderList.length > 0
        ? [
            _c(
              "el-table",
              { attrs: { data: _vm.leaderObject.leaderList, stripe: "" } },
              [
                _c("el-table-column", {
                  attrs: { property: "realName", label: "姓名", width: "" }
                }),
                _c("el-table-column", {
                  attrs: { property: "cardNo", label: "身份证号", width: "" }
                }),
                _c("el-table-column", {
                  attrs: { property: "orgName", label: "所属组织" }
                }),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "90" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm.isSelectLeaderItem(scope.row)
                              ? _c(
                                  "span",
                                  { staticClass: "el-color-predefine" },
                                  [_vm._v("当前的选择")]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.onClickSelectLeader(
                                          scope.row
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("选择")])]
                                )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2582833300
                  )
                })
              ],
              1
            ),
            _c("el-pagination", {
              attrs: {
                layout: "prev, pager, next",
                "pager-count": _vm.leaderObject.pagerCount,
                "current-page": _vm.leaderObject.currentPage,
                "page-size": _vm.leaderObject.pageSize,
                total: _vm.leaderObject.total
              },
              on: { "current-change": _vm.monitorLeaderCurrentPageChange }
            })
          ]
        : [
            _c("div", { staticClass: "no-data" }, [
              _c("img", {
                attrs: { src: require("../../../../assets/img/no-data.png") }
              }),
              _c("div", { staticClass: "no-data-text" }, [
                _vm._v(_vm._s(`"${_vm.selectLeaderName}"`) + "暂时没有数据"),
                _c("br"),
                _vm._v("管理员添加后才能进行选择哦")
              ])
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }