var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      staticClass: "over-training-dialog",
      attrs: {
        title: "温馨提示",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "500px",
        "before-close": _vm.handleClose
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "tips" }, [
        _vm._v(_vm._s(_vm.dialogObject.message))
      ]),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "密码", prop: "passWord" } },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  placeholder: "请输入密码",
                  autocomplete: "off",
                  clearable: ""
                },
                model: {
                  value: _vm.ruleForm.passWord,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "passWord", $$v)
                  },
                  expression: "ruleForm.passWord"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.confirm }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }